<template>
  <section>
    <div v-if="!checkEmailTemplateExists()">Please Select Template</div>
    <div v-else-if="!showFields.emailView">
      <div class="size-selector">
        <label for="cardHeight">Card Height:</label>
        <input
          type="range"
          id="cardHeight"
          min="100"
          max="700"
          v-model="cardHeight"
        />
        <span>{{ cardHeight }}px</span>
      </div>

      <div class="card-container">
        <div
          v-for="(dt, index) in data"
          :key="index"
          class="card"
          :style="{ flex: '0 1 ' + cardWidth + '%' }"
        >
          <el-scrollbar>
            <div
              :style="
                'overflow: scroll;scrollbar-width: thin;max-height:' +
                cardHeight +
                'px;overflow-x: hidden;'
              "
              v-if="dt.html"
              v-html="dt.html"
            ></div>
            <div
              :style="
                'overflow: scroll;scrollbar-width: thin;max-height:' +
                cardHeight +
                'px;overflow-x: hidden;'
              "
              v-else
            >
              No data found
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div v-else>
      <entity-email-view
        :showFields="showFields"
        :isApplicationUserSideValid="isApplicationUserSide"
        :currentMenu="currentMenu"
        :getSelfEntityFilters="getSelfEntityFilters"
        :data="data"
        :emailTemplates="allemailtemplates"
        :keyLabelData="keyLabel"
        @updateRow="updateRow"
        :currentEntity="currentEntity"
        :quickUpdateRowData="quickUpdateRowData"
        @quickUpdateData="handleQuickUpdateData"
      ></entity-email-view>
    </div>
  </section>
</template>

<script>
import EntityEmailView from "./EntityEmailView.vue";
export default {
  name: "EntityContentView",
  data() {
    return {
      htmlStrings: [
        "<div class='content'>HTML Content 1. This is a long content block that will overflow and require scrolling within the card. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum et ligula in nunc bibendum fringilla a eu lectus.</div>",
        "<div class='content'>HTML Content 2. This content is not too long.</div>",
        "<div class='content'>HTML Content 3. Here is some more text that will need scrolling if the card height is too small.</div>",
        "<div class='content'>HTML Content 4. Short content.</div>",
        "<div class='content'>HTML Content 5. Another block of text that might overflow depending on the height setting. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>",
      ],
      cardWidth: 30,
      cardHeight: 300,
      selectedEmailTemplate: this.showFields.selected_email_template,
    };
  },
  props: [
    "entityId",
    "search_string",
    "showFields",
    "isApplicationUserSide",
    "currentMenu",
    "getSelfEntityFilters",
    "data",
    "allemailtemplates",
    "keyLabel",
    "currentEntity",
    "quickUpdateRowData"
  ],
  mounted() {
    this.handleStyles();
  },
  components: {
    EntityEmailView,
  },
  methods: {
    handleStyles() {
      if (this.showFields?.columnsPerRow) {
        switch (this.showFields.columnsPerRow) {
          case 1:
            this.cardWidth = 100;
            this.cardHeight = 600;
            break;
          case 2:
            this.cardWidth = 45;
            this.cardHeight = 400;
            break;
          case 3:
            this.cardWidth = 32;
            this.cardHeight = 300;
            break;
        }
      }
    },
    checkEmailTemplateExists() {
      return this.selectedEmailTemplate;
    },
    updateRow(row){
      this.$emit("updateRow", row);
    },
    handleQuickUpdateData(data) {
      this.$emit("quickUpdateDataProp",data)
    }
  },
  watch: {
    "showFields.columnsPerRow": {
      async handler() {
        // do stuff
        await this.handleStyles();
      },
      deep: true,
    },
    "showFields.selected_email_template": {
      async handler() {
        this.selectedEmailTemplate = this.showFields.selected_email_template;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 16px;
}

.content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.size-selector {
  margin-bottom: 16px;
}

.size-selector label {
  font-weight: bold;
  margin-right: 8px;
}

.size-selector input {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .card-container {
    justify-content: center;
  }

  .card {
    flex: 0 1 100%;
  }
}

@media (max-width: 1200px) {
  .card {
    flex: 0 1 48%;
  }
}

@media (min-width: 1201px) {
  .card {
    flex: 0 1 30%;
  }
}
</style>
